// Customizable Area Start
import { BlockComponent } from "../../../framework/src/BlockComponent";
import { runEngine } from "../../../framework/src/RunEngine";
import { Message } from "../../../framework/src/Message";
import MessageEnum, {
  getName
} from "../../../framework/src/Messages/MessageEnum";
import { image_art, image_business, image_comedy, image_education, image_family, image_fiction, image_film, image_fitness, image_government, image_history, image_lesiure, image_music, image_news, image_religion, image_science, image_society, image_sports, image_stuffs, image_tech, image_truecrime } from "./assets";
import { getStorageData } from "../../../framework/src/Utilities";

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
}

interface Category {
  id: number;
  img: string;
  label: string;
}

interface FormDataStructure {
  id: "6",
  type: "email_account",
  attributes: {
    "first_name": "shaivam",
    "last_name": "chouhan",
    "user_name": "Shivam@31",
    "email": "shivam1@gmail.com",
    "full_phone_number": "919877665544",
    "date_of_birth": "2000-06-29",
    "country_code": 91,
    "user_location": "pune",
    "phone_number": 9888665584,
    "activated": true,
    "social_media_url": {
        "facebook": "https://www.facebook.com/",
        "instagram": "https://www.instagram.com/",
        "x": "https://twitter.com/",
        "linkedin": "https://in.linkedin.com/"
    },
    "podcast_categories_ids": [
        "K12",
        "Higher Education"
    ]
}
}
export interface S { 
  data : Category[],
  selectedId : number[]
  formStats : FormDataStructure;
}

export interface SS {
  id: any;
}

export default class EmailRegistrationCategoriesController extends BlockComponent<
  Props,
  S,
  SS
> {

  getQuestionStatsCallId: string = "";
  constructor(props: Props) {
    super(props);
    this.subScribedMessages = [
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.NavigationPayLoadMessage),
      getName(MessageEnum.CountryCodeMessage)
    ];
    this.receive = this.receive.bind(this);

    runEngine.attachBuildingBlock(this, this.subScribedMessages);

    this.state = {  
      data: [
        { id: 1, img: image_art, label: "ARTS" },
        { id: 2, img: image_business, label: "BUSINESS" },
        { id: 3, img: image_comedy, label: "COMEDY" },
        { id: 4, img: image_education, label: "EDUCATION" },
        { id: 5, img: image_fiction, label: "FICTION" },
        { id: 6, img: image_government, label: "GOVERNMENT" },
        { id: 7, img: image_history, label: "HISTORY" },
        { id: 8, img: image_fitness, label: "HEALTH AND FITNESS" },
        { id: 9, img: image_family, label: "KIDS AND FAMILY" },
        { id: 10, img: image_science, label: "SCIENCE" },
        { id: 11, img: image_lesiure, label: "LEISURE" },
        { id: 12, img: image_music, label: "MUSIC" },
        { id: 2, img: image_news, label: "NEWS" },
        { id: 3, img: image_religion, label: "RELIGION" },
        { id: 3, img: image_film, label: "TV & FILM" },
        { id: 4, img: image_society, label: "SOCIETY AND CULTURE" },
        { id: 5, img: image_sports, label: "SPORTS" },
        { id: 6, img: image_tech, label: "TECHNOLOGY" },
        { id: 7, img: image_truecrime, label: "TRUE CRIME" },
        { id: 8, img: image_stuffs, label: "OTHERS" },
      ],
      selectedId : [],
      formStats : {
        id: "6",
        type: "email_account",
        attributes: {
            "first_name": "shaivam",
            "last_name": "chouhan",
            "user_name": "Shivam@31",
            "email": "shivam1@gmail.com",
            "full_phone_number": "919877665544",
            "date_of_birth": "2000-06-29",
            "country_code": 91,
            "user_location": "pune",
            "phone_number": 9888665584,
            "activated": true,
            "social_media_url": {
                "facebook": "https://www.facebook.com/",
                "instagram": "https://www.instagram.com/",
                "x": "https://twitter.com/",
                "linkedin": "https://in.linkedin.com/"
            },
            "podcast_categories_ids": [
                "K12",
                "Higher Education"
            ]
      }
    }    
  }     
}

async receive(from: string, message: Message) {
  runEngine.debugLog("Message Recived", message);  
  this.handleResForQuestionStats(from,message)  
}

postApi = async () => {
  const Registration = await getStorageData("Registration",true)
  const SocialLinks = await getStorageData("SocialLinks",true)  
    const header = {
      "Content-Type": configJSON.validationApiContentType,
    };
    const httpBody = {
      "data": {
        "attributes": {
            "first_name":Registration.first_name,
            "last_name":Registration.last_name,
            "user_name":Registration.username,
            "full_phone_number": Registration.contact_number,
            "date_of_birth": [Registration.year,Registration.month,Registration.day].join("-"),
            "user_location":Registration.location,
            "email": Registration.email_address,
            "password": Registration.password,
            "password_confirmation": Registration.confirm_password,
            "social_media_url": {
                "facebook": SocialLinks.facebook,
                "instagram": SocialLinks.instagram,
                "x": SocialLinks.x,
                "linkedin": SocialLinks.linkedIn
            },
            "podcast_categories_ids": this.state.selectedId
        },
        "type": "email_account"
    }
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getQuestionStatsCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getQuestionStatsEndpoint
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.apiMethodTypeAddDetail
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  handleResForQuestionStats = async (from: string, message: Message) => {
    if (
      this.getQuestionStatsCallId ===
      message.getData(getName(MessageEnum.RestAPIResponceDataMessage))
    ) {
      const responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      if(responseJson){
        this.setState({formStats:responseJson})
      }
    }
  };  

handleBoxes =(item:any) =>{    
  this.setState((prevState) => {
    const isSelected = prevState.selectedId.includes(item.id);
    const newSelectedIds = isSelected
      ? prevState.selectedId.filter((id) => id !== item.id)
      : [...prevState.selectedId, item.id];
    return { selectedId: newSelectedIds };
  });  
}
}
 // Customizable Area End
