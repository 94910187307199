// Customizable Area Start
import React from "react";
import {
    Box,
    styled,
    Grid,
    TextField,
    Button,
    FormControl,
    Typography,
    Select,
    MenuItem
} from "@mui/material";
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import EmailAccountRegistrationController, {
    Props,
} from "./EmailAccountRegistrationController";
import { checkbox, logoSvg, signUp_background } from "./assets";
import * as Yup from "yup";
import { Formik, Form } from "formik";
import { handleNavigation } from "../../utilities/src/CustomHelpers";
import { setStorageData } from "../../../framework/src/Utilities";

export default class EmailAccountRegistration extends EmailAccountRegistrationController {
    constructor(props: Props) {
        super(props);
    }
    validationSchema = Yup.object().shape({

    });
    render() {
        return (
            <StyledWrapperSignUp>
                <Grid className="container">
                    <Grid className="leftContainer">
                        <Formik
                            initialValues={{
                                first_name: "",
                                last_name: "",
                                username: "",
                                day: "",
                                month : "",
                                year : "",
                                password : "",
                                confirm_password : "",
                                location : "",
                                email_address : "",
                                contact_number : "",
                                gender : "",
                                race : "",
                            }}
                            data-test-id="formikWrapper"
                            validationSchema={this.validationSchema}
                            onSubmit={(values, { setSubmitting }) => {
                                setStorageData("Registration",JSON.stringify(values))
                                handleNavigation("EmailRegistrationSocialLinks",this.props)
                            }}
                        >
                            {({ handleChange, handleSubmit, values }) => (
                                <Form onSubmit={handleSubmit}>
                                    <Box className="logoImg">
                                        <img src={logoSvg} alt="logo" />
                                    </Box>
                                    <Box className="title">Sign Up</Box>
                                    <Grid className="fields">
                                        <Box><label className="label">First Name<span>*</span></label></Box>
                                        <TextField id="outlined-basic"
                                            value={values.first_name}
                                            name="first_name"
                                            onChange={handleChange}
                                            sx={{
                                                width: "100%",
                                                marginTop: "4%",
                                                "& .MuiOutlinedInput-root": {
                                                    "& fieldset": {
                                                        borderColor: "#CBD5E1",
                                                    },
                                                    "&:hover fieldset": {
                                                        borderColor: "#CBD5E1",
                                                    },
                                                    "&.Mui-focused fieldset": {
                                                        borderColor: "#CBD5E1",
                                                    },
                                                },
                                                "& .MuiInputLabel-root": {
                                                    color: "#CBD5E1",
                                                },
                                                "& .MuiInputBase-input": {
                                                    color: "#CBD5E1",
                                                },
                                            }}
                                        />

                                    </Grid>

                                    <Grid className="fields">
                                        <Box><label className="label">Last Name<span>*</span></label></Box>
                                        <TextField id="outlined-basic"
                                            value={values.last_name}
                                            name="last_name"
                                            onChange={handleChange}
                                            sx={{
                                                width: "100%",
                                                marginTop: "4%",
                                                "& .MuiOutlinedInput-root": {
                                                    "& fieldset": {
                                                        borderColor: "#CBD5E1",
                                                    },
                                                    "&:hover fieldset": {
                                                        borderColor: "#CBD5E1",
                                                    },
                                                    "&.Mui-focused fieldset": {
                                                        borderColor: "#CBD5E1",
                                                    },
                                                },
                                                "& .MuiInputLabel-root": {
                                                    color: "#CBD5E1",
                                                },
                                                "& .MuiInputBase-input": {
                                                    color: "#CBD5E1",
                                                },
                                            }}
                                        />

                                    </Grid>

                                    <Grid className="fields">
                                        <Box><label className="label">Username<span>*</span></label></Box>
                                        <TextField id="outlined-basic"
                                            value={values.username}
                                            name="username"
                                            onChange={handleChange}
                                            sx={{
                                                width: "100%",
                                                marginTop: "4%",
                                                "& .MuiOutlinedInput-root": {
                                                    "& fieldset": {
                                                        borderColor: "#CBD5E1",
                                                    },
                                                    "&:hover fieldset": {
                                                        borderColor: "#CBD5E1",
                                                    },
                                                    "&.Mui-focused fieldset": {
                                                        borderColor: "#CBD5E1",
                                                    },
                                                },
                                                "& .MuiInputLabel-root": {
                                                    color: "#CBD5E1",
                                                },
                                                "& .MuiInputBase-input": {
                                                    color: "#CBD5E1",
                                                },
                                            }}
                                        />

                                    </Grid>

                                    <Grid className="fields">
                                        <Box><label className="label">Date Of Birth<span>*</span></label></Box>
                                        <Grid container spacing={2} sx={{ marginTop: "4%" }}>
                                            <Grid item xs={4}>
                                                <Box sx={{ marginBottom: "8px" }}>
                                                    <Typography variant="body2" className="label">Day</Typography>
                                                </Box>
                                                <FormControl fullWidth variant="outlined"                                               

                                                    sx={{
                                                        "& .MuiOutlinedInput-root": {
                                                            "& fieldset": {
                                                                borderColor: "#FFFFFF",
                                                            },
                                                            "&:hover fieldset": {
                                                                borderColor: "#FFFFFF",
                                                            },
                                                            "&.Mui-focused fieldset": {
                                                                borderColor: "#FFFFFF",
                                                            },
                                                            "& .MuiSelect-select": {
                                                                color: "#FFFFFF",
                                                            },
                                                        },
                                                        "& .MuiInputLabel-root": {
                                                            color: "transparent",
                                                        },
                                                        "& .MuiSvgIcon-root": {
                                                            color: "#FFFFFF",
                                                        },
                                                    }}>
                                                    <Select
                                                     value={values.day}
                                                     name="day"
                                                     onChange={handleChange}
                                                        displayEmpty
                                                        defaultValue=""
                                                        data-test-id="dayBtn"
                                                        IconComponent={(props) => (
                                                            <KeyboardArrowDownIcon {...props} sx={{ color: "#FFFFFF" }} />
                                                        )}
                                                        sx={{ color: "#FFFFFF" }}
                                                    >
                                                        <MenuItem value="" disabled>
                                                            DD
                                                        </MenuItem>
                                                        <MenuItem value={1}>1</MenuItem>
                                                        <MenuItem value={2}>2</MenuItem>
                                                        <MenuItem value={3}>3</MenuItem>
                                                    </Select>
                                                </FormControl>
                                            </Grid>

                                            <Grid item xs={4}>
                                                <Box sx={{ marginBottom: "8px" }}>
                                                    <Typography variant="body2" className="label">Month</Typography>
                                                </Box>
                                                <FormControl fullWidth variant="outlined" sx={{
                                                    "& .MuiOutlinedInput-root": {
                                                        "& fieldset": {
                                                            borderColor: "#FFFFFF",
                                                        },
                                                        "&:hover fieldset": {
                                                            borderColor: "#FFFFFF",
                                                        },
                                                        "&.Mui-focused fieldset": {
                                                            borderColor: "#FFFFFF",
                                                        },
                                                        "& .MuiSelect-select": {
                                                            color: "#FFFFFF",
                                                        },
                                                    },
                                                    "& .MuiInputLabel-root": {
                                                        color: "transparent",
                                                    },
                                                    "& .MuiSvgIcon-root": {
                                                        color: "#FFFFFF",
                                                    },
                                                }}>
                                                    <Select
                                                    value={values.month}
                                                    name="month"
                                                    onChange={handleChange}
                                                        displayEmpty
                                                        defaultValue=""
                                                        data-test-id="monthBtn"
                                                        IconComponent={(props) => (
                                                            <KeyboardArrowDownIcon {...props} sx={{ color: "#FFFFFF" }} />
                                                        )}
                                                        sx={{ color: "#FFFFFF" }}
                                                    >
                                                        <MenuItem value="" disabled>
                                                            MM
                                                        </MenuItem>
                                                        <MenuItem value={1}>January</MenuItem>
                                                        <MenuItem value={2}>February</MenuItem>
                                                        <MenuItem value={3}>March</MenuItem>
                                                    </Select>
                                                </FormControl>
                                            </Grid>
                                            <Grid item xs={4}>
                                                <Box sx={{ marginBottom: "8px" }}>
                                                    <Typography variant="body2" className="label">Year</Typography>
                                                </Box>
                                                <FormControl fullWidth variant="outlined" sx={{
                                                    "& .MuiOutlinedInput-root": {
                                                        "& fieldset": {
                                                            borderColor: "#FFFFFF",
                                                        },
                                                        "&:hover fieldset": {
                                                            borderColor: "#FFFFFF",
                                                        },
                                                        "&.Mui-focused fieldset": {
                                                            borderColor: "#FFFFFF",
                                                        },
                                                        "& .MuiSelect-select": {
                                                            color: "#FFFFFF",
                                                        },
                                                    },
                                                    "& .MuiInputLabel-root": {
                                                        color: "transparent",
                                                    },
                                                    "& .MuiSvgIcon-root": {
                                                        color: "#FFFFFF",
                                                    },
                                                }}>
                                                    <Select  
                                                     value={values.year}
                                                     name="year"
                                                     onChange={handleChange}                                                                                                     
                                                        displayEmpty
                                                        defaultValue=""
                                                        data-test-id="yearBtn"
                                                        IconComponent={(props) => (
                                                            <KeyboardArrowDownIcon {...props} sx={{ color: "#FFFFFF" }} />
                                                        )}
                                                        sx={{ color: "#FFFFFF" }}
                                                    >
                                                        <MenuItem value="" disabled>
                                                            YYYY
                                                        </MenuItem>
                                                        <MenuItem value={2000}>2000</MenuItem>
                                                        <MenuItem value={2001}>2001</MenuItem>
                                                        <MenuItem value={2002}>2002</MenuItem>
                                                    </Select>
                                                </FormControl>
                                            </Grid>
                                        </Grid>
                                    </Grid>


                                    <Grid className="fields">
                                        <Box><label className="label">Password<span>*</span></label></Box>
                                        <TextField id="outlined-basic"
                                        value={values.password}
                                        name="password"
                                        onChange={handleChange}
                                            sx={{
                                                width: "100%",
                                                marginTop: "4%",
                                                "& .MuiOutlinedInput-root": {
                                                    "& fieldset": {
                                                        borderColor: "#CBD5E1",
                                                    },
                                                    "&:hover fieldset": {
                                                        borderColor: "#CBD5E1",
                                                    },
                                                    "&.Mui-focused fieldset": {
                                                        borderColor: "#CBD5E1",
                                                    },
                                                },
                                                "& .MuiInputLabel-root": {
                                                    color: "#CBD5E1",
                                                },
                                                "& .MuiInputBase-input": {
                                                    color: "#CBD5E1",
                                                },
                                            }}
                                        />

                                    </Grid>

                                    <Grid className="fields">
                                        <Box><label className="label">Confirm Password<span>*</span></label></Box>
                                        <TextField id="outlined-basic"
                                        value={values.confirm_password}
                                        name="confirm_password"
                                        onChange={handleChange}
                                            sx={{
                                                width: "100%",
                                                marginTop: "4%",
                                                "& .MuiOutlinedInput-root": {
                                                    "& fieldset": {
                                                        borderColor: "#CBD5E1",
                                                    },
                                                    "&:hover fieldset": {
                                                        borderColor: "#CBD5E1",
                                                    },
                                                    "&.Mui-focused fieldset": {
                                                        borderColor: "#CBD5E1",
                                                    },
                                                },
                                                "& .MuiInputLabel-root": {
                                                    color: "#CBD5E1",
                                                },
                                                "& .MuiInputBase-input": {
                                                    color: "#CBD5E1",
                                                },
                                            }}
                                        />

                                    </Grid>

                                    <Grid className="fields">
                                        <Box><label className="label">Location<span>*</span></label></Box>
                                        <TextField id="outlined-basic"
                                        value={values.location}
                                        onChange={handleChange}
                                        name = "location"
                                            sx={{
                                                width: "100%",
                                                marginTop: "4%",
                                                "& .MuiOutlinedInput-root": {
                                                    "& fieldset": {
                                                        borderColor: "#CBD5E1",
                                                    },
                                                    "&:hover fieldset": {
                                                        borderColor: "#CBD5E1",
                                                    },
                                                    "&.Mui-focused fieldset": {
                                                        borderColor: "#CBD5E1",
                                                    },
                                                },
                                                "& .MuiInputLabel-root": {
                                                    color: "#CBD5E1",
                                                },
                                                "& .MuiInputBase-input": {
                                                    color: "#CBD5E1",
                                                },
                                            }}
                                        />

                                    </Grid>
                                    <Grid className="fields">
                                        <Box><label className="label">Email Address<span>*</span></label></Box>
                                        <TextField id="outlined-basic"
                                        value={values.email_address}
                                        onChange={handleChange}
                                        name = "email_address"
                                            sx={{
                                                width: "100%",
                                                marginTop: "4%",
                                                "& .MuiOutlinedInput-root": {
                                                    "& fieldset": {
                                                        borderColor: "#CBD5E1",
                                                    },
                                                    "&:hover fieldset": {
                                                        borderColor: "#CBD5E1",
                                                    },
                                                    "&.Mui-focused fieldset": {
                                                        borderColor: "#CBD5E1",
                                                    },
                                                },
                                                "& .MuiInputLabel-root": {
                                                    color: "#CBD5E1",
                                                },
                                                "& .MuiInputBase-input": {
                                                    color: "#CBD5E1",
                                                },
                                            }}
                                        />

                                    </Grid>
                                    <Grid className="fields">
                                        <Box><label className="label">Contact Number<span>*</span></label></Box>
                                        <TextField id="outlined-basic"
                                        value={values.contact_number}
                                        onChange={handleChange}
                                        name = "contact_number"
                                            sx={{
                                                width: "100%",
                                                marginTop: "4%",
                                                "& .MuiOutlinedInput-root": {
                                                    "& fieldset": {
                                                        borderColor: "#CBD5E1",
                                                    },
                                                    "&:hover fieldset": {
                                                        borderColor: "#CBD5E1",
                                                    },
                                                    "&.Mui-focused fieldset": {
                                                        borderColor: "#CBD5E1",
                                                    },
                                                },
                                                "& .MuiInputLabel-root": {
                                                    color: "#CBD5E1",
                                                },
                                                "& .MuiInputBase-input": {
                                                    color: "#CBD5E1",
                                                },
                                            }}
                                        />

                                    </Grid>

                                    <Grid className="fields">
                                        <Box>
                                            <label className="label">Gender</label>
                                        </Box>
                                        <FormControl id="gender" fullWidth variant="outlined" sx={{
                                            marginTop: "4%",
                                            width: "100%",
                                            "& .MuiOutlinedInput-root": {
                                                "&:hover fieldset": {
                                                    borderColor: "#CBD5E1",
                                                },
                                                "& fieldset": {
                                                    borderColor: "#CBD5E1",
                                                },
                                                "&.Mui-focused fieldset": {
                                                    borderColor: "#CBD5E1",
                                                },
                                                "& .MuiSelect-select": {
                                                    color: "#CBD5E1",
                                                    "&::placeholder": {
                                                        color: "#B0B0B0",
                                                    },
                                                },
                                            },
                                            "& .MuiSvgIcon-root": {
                                                color: "#CBD5E1",
                                            },
                                            "& .MuiInputLabel-root": {
                                                color: "#CBD5E1",
                                            },
                                        }}>
                                            <Select
                                            value={values.gender}
                                            onChange={handleChange}
                                            name = "gender"
                                                id="gender"
                                                data-test-id="gender"
                                                displayEmpty
                                                IconComponent={(props) => (
                                                    <KeyboardArrowDownIcon {...props} sx={{ color: "#CBD5E1" }} />
                                                )}
                                                sx={{ color: "#CBD5E1" }}
                                            >
                                                <MenuItem value="male">Male</MenuItem>
                                                <MenuItem value="female">Female</MenuItem>
                                                <MenuItem value="other">Other</MenuItem>
                                            </Select>
                                        </FormControl>
                                    </Grid>

                                    <Grid className="fields">
                                        <Box>
                                            <label className="label">Race</label>
                                        </Box>
                                        <FormControl id="race" fullWidth sx={{
                                            width: "100%",
                                            marginTop: "4%",
                                            "& .MuiOutlinedInput-root": {
                                                "& fieldset": {
                                                    borderColor: "#CBD5E1",
                                                },
                                                "&:hover fieldset": {
                                                    borderColor: "#CBD5E1",
                                                },
                                                "&.Mui-focused fieldset": {
                                                    borderColor: "#CBD5E1",
                                                },
                                                "& .MuiSelect-select": {
                                                    color: "#CBD5E1",
                                                    "&::placeholder": {
                                                        color: "#B0B0B0",
                                                    },
                                                },
                                            },
                                            "& .MuiSvgIcon-root": {
                                                color: "#CBD5E1",
                                            },
                                            "& .MuiInputLabel-root": {
                                                color: "#CBD5E1",
                                            },
                                        }}>
                                            <Select
                                            value={values.race}
                                            onChange={handleChange}
                                            data-test-id="race"
                                            name = "race"
                                                displayEmpty
                                                id="race"
                                                IconComponent={(props) => (
                                                    <KeyboardArrowDownIcon {...props} sx={{ color: "#CBD5E1" }} />
                                                )}
                                                sx={{ color: "#CBD5E1" }}
                                            >
                                                <MenuItem value="female">Female</MenuItem>
                                                <MenuItem value="male">Male</MenuItem>
                                                <MenuItem value="other">Other</MenuItem>
                                            </Select>
                                        </FormControl>
                                    </Grid>


                                    <Box className="checkBox">
                                        <img src={checkbox} alt="checkbox" />
                                        <Box className="checkboxContent">You have to agree with Privacy policy and <br />Terms and conditions to Sign up</Box>
                                    </Box>

                                    <Button className="btnContinue" data-test-id = "submitBtn" type="submit"><b>Continue</b></Button>
                                    <Box className="login"><b>Already have An Account? <a href="EmailAccountRegistration" style={{ textDecoration: "none", color: "#1D4ED8", cursor: "pointer" }}>Log in</a></b></Box>

                                </Form>
                            )}
                        </Formik>
                    </Grid>
                    <Grid className="rightContainer">
                        <img src={signUp_background} alt="signup_bg" className="imageSignUp" />
                    </Grid>
                </Grid>
            </StyledWrapperSignUp>
        );
    }

}
const StyledWrapperSignUp = styled("div")({
    margin: "0 auto",
    height: "auto",
    width: "100%",
    background: "black",
    "& *": {
        boxSizing: "border-box",
    },
    "& .login": {
        color: "white",
        fontFamily: "Poppins",
        fontWeight: 400,
        fontSize: "12px",
        textAlign: "center",
        marginTop: "4%",
    },
    "& .btnContinue": {
        height: "4%",
        width: "100%",
        background: "#FF0807",
        color: "white",
        borderRadius: "8px",
        fontFamily: "Poppins",
        fontSize: "18px",
        fontWeight: 900,
        textTransform: "capitalize",
        marginTop: "3%",
        "&:hover": {
            background: "#FF0807",
            color: "white"
        },
    },
    "& .checkBox": {
        display: "flex",
        justifyContent: "flex-start",
        alignItems: "center",
        padding: "0%",
        marginTop: "7%",
        height: "6%",
        width: "20%"
    },
    "& .checkboxContent": {
        color: "white",
        fontFamily: "Poppins",
        fontSize: "14px",
        fontWeight: 900,
        borderLeft: "4px solid #FF0807",
        marginLeft: "15%",
        paddingLeft: "10%",
        height: "70%",
        borderRadius: "4px",
        display: "flex",
        alignItems: "center",
        whiteSpace: "nowrap",
    },
    "& .TermsCondition": {
        color: "#1D4ED8",
    },
    "& .title": {
        fontSize: "20px",
        fontWeight: 900,
        color: "white",
        textAlign: "center",
        fontFamily: "Poppins",
        marginTop: "7%",
        marginBottom: "4%"
    },
    "& .logoImg": {
        display: "flex",
        justifyContent: "center",
    },
    "& .fields": {
        marginTop: "5%"
    },
    "& .label": {
        color: "white",
        fontFamily: "Poppins",
        fontSize: "14px",
        fontWeight: 900
    },
    "& .container": {
        display: "flex",
        flexDirection: "row",
        justifyContent: "space-between",
        height: "auto",
        marginBottom: "10%",
    },
    "& .leftContainer": {
        width: "45%",
        padding: "3% 10%",
    },
    "& .rightContainer": {
        width: "55%",
        display: "flex",
        justifyContent: "flex-start",
        alignItems: "flex-start",
        paddingTop: "5%",
    },
    "& .imageSignUp": {
        width: "100%",
        height: "auto",
    },
})
// Customizable Area End
